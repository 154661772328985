import React from "react"
import ReactPlayer from "react-player"
import PlayText from "./playtext"
import SectionNav from "./sectionnav"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import {
  toggleVidAutoAdvance,
  toggleVidShowSubtitles,
  setLastTextTabSelection,
} from "../../../../state/app"
import parse from "html-react-parser"

class KeyPage extends React.Component {
  constructor(props) {
    super(props)
    this.selectTab = this.selectTab.bind(this)
    this.onVideoProgress = this.onVideoProgress.bind(this)
    this.setCurrentSubtitles = this.setCurrentSubtitles.bind(this)
    this.toggleAutoAdvance = this.toggleAutoAdvance.bind(this)
    this.toggleShowSubtitles = this.toggleShowSubtitles.bind(this)

    this.state = {
      vidPosition: 0,
      currentSubtitles: null,
    }
  }

  selectTab(tabName, e) {
    e.preventDefault()
    this.props.dispatch(setLastTextTabSelection(tabName))
  }

  toggleAutoAdvance() {
    this.props.dispatch(toggleVidAutoAdvance())
  }

  toggleShowSubtitles() {
    this.props.dispatch(toggleVidShowSubtitles())
  }

  onVideoProgress(played, loaded) {
    this.setState((state) => ({
      ...this.state,
      vidPosition: played.playedSeconds,
    }))
    var blockContinue =
      this.props.keypage.numSections === this.props.keypage.sectionPosition &&
      this.props.hideNextScene
    if (played.played === 1) {
      if (this.props.autoAdvance && !blockContinue) {
        navigate(
          "/plays/" + this.props.playId + "/" + this.props.keypage.nextKeypageId
        )
      }
      return
    }
    this.setCurrentSubtitles(played.playedSeconds)
  }

  setCurrentSubtitles(playedSeconds) {
    let targetSu = this.props.keypage.sceneUnits.find((su) => {
      return playedSeconds > su.vidIn && playedSeconds < su.vidOut
    })
    this.setState((state) => ({
      ...this.state,
      currentSubtitles: targetSu,
    }))
  }

  render() {
    let playId = this.props.playId
    var keypage = this.props.keypage
    var synopsis = keypage.synopsis ? keypage.synopsis : []
    let baseVidPath = "https://downloads.shakespeareinbits.com/media/vidcontent"

    var notesLineKey = 0
    var paraLineKey = 0
    var subtitlesLineKey1 = 0
    var subtitlesLineKey2 = 0
    var subtitlesLineKey3 = 0

    return (
      <div className="outer-keypage-container container py-0 mt-0 mb-4">
        <div className="columns my-0">
          {/* Video / Scene Nav area */}
          <div className="column is-7 keypage-vid-area">
            <SectionNav
              playId={playId}
              keypage={keypage}
              showSceneNav={true}
              hideNextScene={this.props.hideNextScene}
              hidePrevScene={this.props.hidePrevScene}
            />
            {/* Video Player */}
            <div className="section pt-1 pb-0 px-0">
              <div className="container">
                <div
                  className={`is-overlay
                  ${
                    this.state.currentSubtitles == null ||
                    !this.props.showSubtitles
                      ? " is-hidden"
                      : ""
                  }
                `}
                >
                  <div className="has-text-centered has-text-white subtitle-box mt-3">
                    {this.state.currentSubtitles != null && (
                      <div
                        className="mt-1"
                        key={`subtitles-line-key-1-${subtitlesLineKey1++}`}
                      >
                        <b>{this.state.currentSubtitles.speaker}</b>
                        {this.state.currentSubtitles.lineWraps.map(
                          (lineWrap) => {
                            return lineWrap.lines.map((line) => {
                              return (
                                <div
                                  key={`subtitles-line-key-2-${subtitlesLineKey2++}`}
                                >
                                  {line.chunks.map((chunk) => {
                                    return (
                                      <span
                                        key={`subtitles-line-key-3-${subtitlesLineKey3++}`}
                                      >
                                        {chunk.text}
                                      </span>
                                    )
                                  })}
                                </div>
                              )
                            })
                          }
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <ReactPlayer
                  url={
                    baseVidPath +
                    "/" +
                    playId +
                    "/h264/" +
                    keypage.keypageId +
                    ".ipad.mp4"
                  }
                  width="100%"
                  height="100%"
                  playing={this.props.autoAdvance}
                  controls={true}
                  download={false}
                  onProgress={this.onVideoProgress}
                  playsinline={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </div>
            </div>
            {/* Switch / control area */}
            <div className="level mt-1 is-mobile">
              <div className="level-right">
                <div className="level-item has-text-sibblack">Subtitles</div>
                <div className="level-item">
                  <div className="field">
                    <label className="switch is-rounded">
                      <input
                        type="checkbox"
                        value={this.props.showSubtitles}
                        checked={this.props.showSubtitles}
                        onChange={(e) => this.toggleShowSubtitles(e)}
                      />
                      <span className="check is-sibblack" />
                    </label>
                  </div>
                </div>
                <div className="level-item has-text-sibblack">Auto-Advance</div>
                <div className="level-item">
                  <div className="field">
                    <label className="switch is-rounded">
                      <input
                        type="checkbox"
                        value={this.props.autoAdvance}
                        checked={this.props.autoAdvance}
                        onChange={(e) => this.toggleAutoAdvance(e)}
                      />
                      <span className="check is-sibblack" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Text Area */}
          <div className="column">
            <div className="section py-1 outer-playtext-section">
              <div className="container">
                <div className="tabs is-fullwidth is-boxed">
                  <ul>
                    <li
                      className={
                        this.props.lastTextTabSelection === "playtext"
                          ? "is-active"
                          : ""
                      }
                    >
                      <a
                        onClick={(e) => this.selectTab("playtext", e)}
                        onKeyDown={(e) => this.selectTab("playtext", e)}
                        aria-hidden="true"
                        href="/"
                      >
                        <span
                          className={`label ${
                            this.props.lastTextTabSelection === "playtext"
                              ? ""
                              : ""
                          }`}
                        >
                          Text
                        </span>
                      </a>
                    </li>
                    <li
                      className={
                        this.props.lastTextTabSelection === "notes"
                          ? "is-active"
                          : ""
                      }
                    >
                      <a
                        onClick={(e) => this.selectTab("notes", e)}
                        onKeyDown={(e) => this.selectTab("notes", e)}
                        aria-hidden="true"
                        href="/"
                      >
                        <span
                          className={`label ${
                            this.props.lastTextTabSelection === "notes"
                              ? "is-active"
                              : ""
                          }`}
                        >
                          Notes
                        </span>
                      </a>
                    </li>
                    <li
                      className={
                        this.props.lastTextTabSelection === "summary"
                          ? "is-active"
                          : ""
                      }
                    >
                      <a
                        onClick={(e) => this.selectTab("summary", e)}
                        onKeyDown={(e) => this.selectTab("summary", e)}
                        aria-hidden="true"
                        href="/"
                      >
                        <span
                          className={`label ${
                            this.props.lastTextTabSelection === "summary"
                              ? ""
                              : ""
                          }`}
                        >
                          Summary
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={`section py-1 px-0`}>
                  <div
                    className={
                      this.props.lastTextTabSelection === "playtext"
                        ? ""
                        : "is-hidden"
                    }
                  >
                    <PlayText
                      keypage={keypage}
                      playId={playId}
                      vidPosition={this.state.vidPosition}
                      setCurrentSubtitles={this.setCurrentSubtitles}
                    />
                  </div>
                  <div
                    className={
                      this.props.lastTextTabSelection === "notes"
                        ? ""
                        : "is-hidden"
                    }
                  >
                    <h6 className="title is-6">Section Notes</h6>
                    {keypage.notes.map((line) => {
                      return (
                        <div
                          className="content"
                          key={"notesline_" + notesLineKey++}
                        >
                          {parse(line)}
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className={
                      this.props.lastTextTabSelection === "summary"
                        ? ""
                        : "is-hidden"
                    }
                  >
                    <h6 className="title is-6">What happens in this scene</h6>
                    {synopsis.map((para) => {
                      return (
                        <p className="mb-1" key={"paraline_" + paraLineKey++}>
                          {para}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    autoAdvance: state.app.videoAutoAdvance,
    showSubtitles: state.app.videoShowSubtitles,
    lastTextTabSelection: state.app.lastTextTabSelection,
  }),
  null
)(KeyPage)
