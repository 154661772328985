import React from "react"
import { Link } from "gatsby"

class SectionNav extends React.Component {
  render() {
    const keypage = this.props.keypage
    const playId = this.props.playId
    return (
      <div className={`section py-1 px-0 is-hidden-mobile`}>
        <div className="box has-background-sibgrey2lighter is-hidden-mobile section-nav-backing-box">
          <div className="columns is-hidden-mobile is-centered is-vcentered">
            <div className="column is-1"></div>
            <div className="column py-1">
              <div className="columns pr-1">
                {keypage.sceneKpIndex.map((id, index) => {
                  return (
                    <div
                      className="column m-0 p-0"
                      key={id}
                      data-tooltip={`Section ${index + 1} of ${
                        keypage.numSections
                      }`}
                    >
                      <Link to={"/plays/" + playId + "/" + id}>
                        <div
                          className={`box section-nav-box px-0 py-1 ${
                            id === keypage.keypageId
                              ? "has-background-sibblack"
                              : id < keypage.keypageId
                              ? "has-background-sibyellow"
                              : "has-background-sibgrey4base"
                          }`}
                        >
                          <span
                            className={`
                              has-text-white is-size-7 section-nav-box-label
                              ${id === keypage.keypageId ? "" : "is-hidden"}
                              `}
                          >
                            {index + 1}
                          </span>
                          <span
                            className={`
                              has-text-sibgrey5dark is-size-7 section-nav-box-label
                              ${
                                index === keypage.sceneKpIndex.length - 1
                                  ? ""
                                  : "is-hidden"
                              }
                              `}
                          >
                            {index + 1}
                          </span>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SectionNav
