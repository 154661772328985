import React from "react"
import { Link } from "gatsby"
//import "../../styles/mystyles.scss"
import parse from "html-react-parser"

class PlayText extends React.Component {
  constructor(props) {
    super(props)
    var inlineStates = []
    this.addInlineStates = this.addInlineStates.bind(this)
    this.getInlineValue = this.getInlineValue.bind(this)
    this.getAnnotationLetter = this.getAnnotationLetter.bind(this)

    this.addInlineStates(inlineStates, this.props.keypage)

    this.state = {
      inlineStates,
    }
  }

  addInlineStates(inlineStates, keypage) {
    for (var su of keypage.sceneUnits) {
      for (var lineWraps of su.lineWraps) {
        for (var lines of lineWraps.lines) {
          for (var chunk of lines.chunks) {
            if (chunk.type === "inline") {
              inlineStates.push({
                id: chunk.id,
                original: chunk.text,
                alt: chunk.alt,
                showingAlt: false,
              })
            }
          }
        }
      }
    }
  }

  toggleInlineState(inlineId) {
    if (inlineId === null) {
      return
    }
    let inlineStates = this.state.inlineStates
    var index = inlineStates.findIndex((e) => {
      return e.id === inlineId
    })
    if (index > -1) {
      inlineStates[index].showingAlt = !inlineStates[index].showingAlt
    }
    this.setState((state) => ({
      inlineStates: inlineStates,
      ...this.state,
    }))
  }

  getInlineValue(inlineId) {
    var elem = this.state.inlineStates.find((e) => {
      return e.id === inlineId
    })
    if (elem == null) {
      return "UNDEFINED"
    }
    return elem.showingAlt ? elem.alt : elem.original
  }

  isInlineToggled(inlineId) {
    var elem = this.state.inlineStates.find((e) => {
      return e.id === inlineId
    })
    if (elem == null) {
      return false
    }
    return elem.showingAlt
  }

  // Letter and offset to hack the thing into the centre
  getAnnotationLetter(id) {
    switch (id) {
      case "language":
        return ["L", 0.48, "Language"]
      case "history":
        return ["H", 0.4, "History"]
      case "theme":
        return ["T", 0.44, "Theme"]
      case "themes":
        return ["T", 0.44, "Theme"]
      case "misc":
        return ["M", 0.31, "Misc"]
      case "thinkabout":
        return ["?", 0.51, "Think About"]
      default:
        return ["?", 0.51, "???"]
    }
  }

  render() {
    const keypage = this.props.keypage
    const playId = this.props.playId
    var lineWrapId = 0
    var chunkId = 0
    var speakerKey = 0
    let lines = keypage.sceneUnits.map((value, index) => {
      var speakerName = []
      if (value.speaker != null && !value.speakerContinues) {
        speakerName = [
          <div className={`play-main-text speaker-link`} key={"speaker_" + speakerKey++}>
              <b>{value.speaker}</b>
          </div>,
        ]
      }
      if (
        value.speaker != null &&
        value.speakerLink != null &&
        !value.speakerContinues
      ) {
        speakerName = [
          <Link
            to={"/plays/" + playId + "/cast/" + value.speakerLink}
            key={"speaker_" + speakerKey++}
          >
            <div className="has-text-info play-main-text speaker-link">
                <b>{value.speaker}</b>
            </div>
          </Link>,
        ]
      }
      let speakerLines = value.lineWraps.map((lineWrap, _) => {
        let noteButton
        if (lineWrap.notes.type != null) {
          var annLetter = this.getAnnotationLetter(lineWrap.notes.type)
          noteButton = (
            <>
              <div
                className={`popover is-annotation is-popover-top is-hidden-mobile`}
              >
                <InlineDisplay
                  annLetter={annLetter}
                  text={lineWrap.notes.text}
                />
              </div>
              <div
                className={`popover is-annotation is-popover-right is-hidden-tablet`}
              >
                <InlineDisplay
                  annLetter={annLetter}
                  text={lineWrap.notes.text}
                />
              </div>
            </>
          )
        }
        return (
          <div
            className={lineWrap.notes.type === null ? "" : "annotation-outer"}
            key={"lineWrap_" + lineWrapId++}
          >
            <div
              className={`${
                lineWrap.notes.type === null ? "" : "annotation-inner-1"
              }`}
            >
              {noteButton}
              <div
                className={`${
                  lineWrap.notes.type === null ? "" : "annotation-inner-2"
                }`}
              >
                {lineWrap.lines.map((line) => {
                  return (
                    <div
                      className={`
                    ${lineWrap.notes.type === null ? "" : "annotation-inner-3"}
                    ${lineWrap.notes.type === null ? "unannotated-text" : ""}
                    ${
                      this.props.vidPosition > value.vidIn &&
                      this.props.vidPosition < value.vidOut
                        ? "has-text-sibredorange"
                        : ""
                    }
                  `}
                      key={"line_" + line.lineNumber}
                    >
                      {line.chunks.map((chunk) => {
                        return (
                          <span
                            className={
                              value.type === "stagedir"
                                ? "play-main-text stagedir has-text-sibgrey5dark"
                                : "play-main-text"
                            }
                            key={"chunk_" + chunkId++}
                          >
                            <span
                              className={`
                            play-main-text
                            ${
                              chunk.type === null
                                ? ""
                                : this.isInlineToggled(chunk.id)
                                ? "inline_replaced"
                                : "inline"
                            }
                          `}
                              onClick={(e) => this.toggleInlineState(chunk.id)}
                              onKeyDown={(e) =>
                                this.toggleInlineState(chunk.id)
                              }
                              aria-hidden="true"
                            >
                              {chunk.type === "inline"
                                ? parse(this.getInlineValue(chunk.id))
                                : parse(chunk.text)}
                            </span>
                          </span>
                        )
                      })}
                      <span className={`line-number ${line.lineNumber % 5 !== 0 ? 'is-hidden' : ''}`}>{line.lineNumber}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })
      return speakerName.concat(speakerLines)
    })
    return (
      <div className="ml-0 pl-0">
        <h6 className="title is-6 has-text-sibblack has-text-weight-bold">
          {keypage.location}
        </h6>
        {lines}
      </div>
    )
  }
}

const InlineDisplay = (props) => {
  return (
    <>
      <div className="popover-trigger is-annotation-button">
        <div
          className="is-annotation-letter has-text-white"
          style={{ left: props.annLetter[1] + "em" }}
        >
          {props.annLetter[0]}
        </div>
      </div>
      <div className="popover-content popover-content-inline has-background-sibgrey2lighter popover-frame-reference p-2">
        <h2 className="label is-2 has-text-sibblack play-nav-divider has-text-weight-bold">
          {props.annLetter[2]}
        </h2>
        <div className="annotation-inner-text">{props.text}</div>
      </div>
    </>
  )
}

export default PlayText
