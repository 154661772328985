import React from "react"
import { connect } from "react-redux"

import Layout from "../components/common/layout/layout"
import KeyPageBody from "../components/product/sibplay/keypage/keypagebody"

import PrivateContent from "../components/user/privatecontent"

import { setLastKPForPlay } from "../state/app"

const KeyPageContainer = (props) => {
  const { pageContext } = props
  const { playId, keypage, playTitle, manifest } = pageContext
  props.dispatch(setLastKPForPlay(playId, keypage.keypageId))

  return (
    <Layout>
      <PrivateContent accessLevel={keypage.access}>
        <KeyPageBody
          playId={playId}
          playTitle={playTitle}
          manifest={manifest}
          keypage={keypage}
          subInfo={props.subInfo}
        />
      </PrivateContent>
    </Layout>
  )
}

export default connect(
  (state) => ({
    lastKps: state.app.lastKps,
  }),
  null
)(KeyPageContainer)
