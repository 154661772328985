import React from "react"
import { Link } from "gatsby"

const SceneNav = (props) => {
  return (
    <div className={`scenenav-outer p-1`}>
      {/* Tablet + */}
      <div className={`container is-hidden-mobile`}>
        <div className={`columns is-vcentered is-mobile is-hidden-mobile`}>
          <SceneNavBody {...props}></SceneNavBody>
        </div>
      </div>
      {/* Mobile (centered) */}
      <div className={`container is-hidden-tablet`}>
        <div
          className={`columns is-vcentered is-mobile is-centered is-hidden-tablet`}
        >
          <SceneNavBody {...props}></SceneNavBody>
        </div>
      </div>
    </div>
  )
}

const SceneNavBody = (props) => {
  var title = props.dispTitle
  var nextUrl = props.nextUrl
  var prevUrl = props.prevUrl
  var hidePrevScene = props.hidePrevScene || prevUrl == null
  var hideNextScene = props.hideNextScene || nextUrl == null
  return (
    <>
      {/* Previous Scene Arrow */}
      <div className={`column is-narrow`}>
        <div className={`${hidePrevScene ? "is-hidden" : ""}`}>
          <Link to={hidePrevScene ? "/" : prevUrl}>
            <span className="icon has-text-sibredorange">
              <i
                className="far fa-arrow-alt-circle-left fa-2x"
                style={{ position: "relative", top: "0.2rem" }}
              ></i>
            </span>
          </Link>
        </div>
        <div className={`${hidePrevScene ? "" : "is-hidden"}`}>
          <span className="icon has-text-sibgrey3light">
            <i
              className="far fa-circle fa-2x"
              style={{ position: "relative", top: "0.2rem" }}
            ></i>
          </span>
        </div>
      </div>
      {/* Title */}
      <div className="column is-narrow has-text-centered">
        <h2 className="title is-6">{title}</h2>
      </div>
      {/* Next Scene Arrow */}
      <div className={`column is-narrow`}>
        <div className={`${hideNextScene ? "is-hidden" : ""}`}>
          <Link to={hideNextScene ? "/" : nextUrl}>
            <span className="icon has-text-sibredorange">
              <i
                className="far fa-arrow-alt-circle-right fa-2x"
                style={{ position: "relative", top: "0.2rem" }}
              ></i>
            </span>
          </Link>
        </div>
        <div className={`${hideNextScene ? "" : "is-hidden"}`}>
          <span className="icon has-text-sibgrey3light">
            <i
              className="far fa-circle fa-2x"
              style={{ position: "relative", top: "0.2rem" }}
            ></i>
          </span>
        </div>
      </div>
    </>
  )
}

export default SceneNav
