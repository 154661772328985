import React from "react"

import KeyPage from "./keypage"
import PlayNav from "../playnav/playnav"
import SceneNav from "../playnav/scenenav"

import Seo from "../../../common/layout/seo"

const KeyPageBody = (props) => {
  var keypage = props.keypage
  // kludge, hopefully don't need to calc this too much
  var isUnauthenticated = !props.subInfo
  var hideNextScene = false
  var hidePrevScene = false

  if (isUnauthenticated) {
    if (keypage.prevSceneKeypageIdAccess !== "unauthenticated") {
      hidePrevScene = true
    }
    if (keypage.nextSceneKeypageIdAccess !== "unauthenticated") {
      hideNextScene = true
    }
  }

  if (props.subInfo && props.subInfo.isUnpaid) {
    if (
      keypage.prevSceneKeypageIdAccess !== "unauthenticated" &&
      keypage.prevSceneKeypageIdAccess !== "requireslogin"
    ) {
      hidePrevScene = true
    }
    if (
      keypage.nextSceneKeypageIdAccess !== "unauthenticated" &&
      keypage.nextSceneKeypageIdAccess !== "requireslogin"
    ) {
      hideNextScene = true
    }
  }

  var dispTitleScene = null
  var nextUrlScene = null
  var prevUrlScene = null

  var dispTitleSection = null
  var nextUrlSection = null
  var prevUrlSection = null

  if (props.keypage != null) {
    dispTitleScene = `${keypage.actName} - ${keypage.sceneName}`
    if (keypage.prevSceneKeypageId != null) {
      prevUrlScene = `/plays/${props.playId}/${props.keypage.prevSceneKeypageId}`
    }
    if (keypage.nextSceneKeypageId != null) {
      nextUrlScene = `/plays/${props.playId}/${props.keypage.nextSceneKeypageId}`
    }
    dispTitleSection = `Section ${keypage.sectionPosition} of ${keypage.numSections}`
    if (keypage.prevKeypageId != null) {
      prevUrlSection = `/plays/${props.playId}/${keypage.prevKeypageId}`
    }
    if (keypage.nextKeypageId != null) {
      nextUrlSection = `/plays/${props.playId}/${keypage.nextKeypageId}`
    }
  }

  return (
    <>
      <Seo title={props.playTitle} />
      <PlayNav
        playId={props.playId}
        playTitle={props.playTitle}
        section="theplay"
        showSceneNav={true}
        hidePrevScene={hidePrevScene}
        hideNextScene={hideNextScene}
        manifest={props.manifest}
        keypage={props.keypage}
      />
      <SceneNav
        dispTitle={dispTitleScene}
        prevUrl={prevUrlScene}
        nextUrl={nextUrlScene}
        hideNextScene={hideNextScene}
        hidePrevScene={hidePrevScene}
      />
      <div className="container is-hidden-tablet">
        <SceneNav
          dispTitle={dispTitleSection}
          prevUrl={prevUrlSection}
          nextUrl={nextUrlSection}
          hideNextScene={
            hideNextScene &&
            keypage.sectionPosition === keypage.numSections
          }
          hidePrevScene={hidePrevScene && keypage.sectionPosition === 1}
        />
      </div>
      <KeyPage
        playId={props.playId}
        keypage={props.keypage}
        subInfo={props.subInfo}
        hidePrevScene={hidePrevScene}
        hideNextScene={hideNextScene}
      />
    </>
  )
}

export default KeyPageBody
